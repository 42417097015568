%footer-link {
  font-size: 0.9rem;
  display: inline-block;
 	color: $darkgray;
 	transition: $basic;
 	margin: 0 auto;
 	text-decoration: underline;
 	&:hover {
 		 color: $blue;
 	}
 	&:focus {
    color: $blue;
    box-shadow: 0 0 0 1px $blue;
 	}	  	
 	@include tablet {
 		font-size: .8rem;
 	}
 	@include desktop {
 		font-size: 1rem;
 	}	
}

.site-footer::before {
  content: "";
  width: 100%;
  height: .5rem;
  position: absolute;
  top: -.5rem;
  left: 0;
  z-index: 1;
  background: $darkblue; 
 	@include tablet {
 		 height: .65rem;
 		 top: -.65rem;
 	}
}

.site-footer {
  position: relative;
  background: $lightblue;
  padding: 2rem 0;
	 &-grid {
	   display: block;
		  margin: 0 auto;
   	@include tablet {
		  }
  		@include tablet-wide {
  		}	
  		@include desktop {
  			 max-width: $desktop-medium;
  		}			
	 }
 	&-col {
  		text-align: center;
  		margin: 0 auto;
    padding: 0;
  		@include tablet {
  			 margin: 1rem 0;	
      padding: 0;
  		}
       
 	}	
  &-nav-list {
  		text-align:  center;
    list-style: none;
    margin: 0;
    padding: 0;
  		&-link {
  			@extend %footer-link;
    			display: block;
    			margin: 0 0 .5rem 0;
       font-weight: 400;
    			@include tablet {
         font-size: 0.9rem;
         display: table;
    			}
       &-title {
         color: $darkblue;
         font-family: $nav;
         font-weight: 900;
         letter-spacing: -.5px;
         font-size: 1.1rem;
         padding: 0 0 .5rem 0;
         text-transform: uppercase;
         display: block;
         &:focus {
           color: $white;
           box-shadow: 0 0 0 1px $blue;
         }	  	
         &:hover {
           color: $white;
         }
       }
  		}
    @include tablet {
       text-align: left;
    }
  }
  .social {
		  margin: 1.0rem 0;
  		&-link {
   			@include size(1.75rem, 1.75rem);
   			@include tablet {
   				 @include size(1.35rem, 1.35rem);				
   			}	
   			@include desktop {
   				 @include size(1.75rem, 1.75rem);
   			}				
  		}
	 }
}

.footer-logo {
  padding: 1rem;
  &-img {
    max-width: 180px;  
    height: auto;
  }
}

.footer-extras-share {
  font-size: 1.0rem;
  margin-top: .5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include md {
 	  font-size: 1.1rem;
	 }
	 &-title {
	   font-weight: 700;
	   text-transform: uppercase;
	   color: $darkblue;
	 }
}

.footer-extras-share .social-link {
  margin-left: .5rem;
  display: inline-block;
  border-radius: 50%;
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  background: $darkblue;
  &:hover {
    background: $blue;
  }
}
.footer-extras-share .social-link svg {
  width: 100%;
  fill: $white;
}


.paidfor {
  color: $white;
  font-size: .9rem;
 	padding: .75rem;
 	line-height: 1.3;
 	border: 1px solid rgba($lightgray,.4);
  font-weight: 400;
  width: 90%;
  margin: 2rem auto;
		@include desktop {
		  width: 60%;
		  max-width: 960px;
		}
}

.underline {
  text-decoration: underline;
}

.copyright {
  width: 100%;
  text-align: center;
  font-weight: 400;
 	&-content {
 			font-size: 0.9rem;
 		 padding: 1rem;
    color: $darkgray;
    margin: 0 auto;
 		 @include tablet {
 		 }	
 		 @include desktop {
      max-width: $desktop-medium;
 		 }
 	}
 	&-nav {
  		&-link {
   			@extend %footer-link;
  		}
 	}
}
