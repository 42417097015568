.service-item {
  padding: 1.0rem;
  background: rgba(0,155,221,.75);
  text-align: center;
  grid-column: span 1;
  @include tablet {
    padding: 1.5rem;
  }
  
  &-aspect {
    text-decoration: none !important;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  &-headline {
    color: $white;
    line-height: 1.1;
    margin: 0 auto;
  }
  
}

.team-member {
  padding: 1.0rem;
  text-align: center;
  grid-column: span 1;
  @include tablet {
    padding: 1rem;
  }
  
  &-aspect {
    text-decoration: none !important;
    display: block !important;
    &:hover .team-member-photo-img {
       -moz-transform: scale(1.1);
       -webkit-transform: scale(1.1);
       -o-transform: scale(1.1);
       -ms-transform: scale(1.1);
       transform: scale(1.1);
    }
    &:hover .team-member-text-headline {
       color: $blue;
    }
    &.norollover {
      &:hover .team-member-photo-img {
         -moz-transform: none;
         -webkit-transform: none;
         -o-transform: none;
         -ms-transform: none;
         transform: none;
      }
      &:hover .team-member-text-headline {
         color: $blue;
      }
    }
  }
 
  &-photo {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 100%;
    background: $darkblue;
    overflow: hidden;
    border-radius: 20px;
    margin: 0 auto;
    @include tablet {
      border-radius: 30px;
    }
    &.bio {
      width: 40%;
      height: 100%;
      margin: 0 0 1rem 1rem;
      float: right;
      @include tablet {
        width: 320px;
        height: auto;
        margin: 0 0 2rem 2rem;
      }
      & .team-member-photo-img {
        padding-bottom: 130% !important;
      }
    }
    &-img {
      width: 100%;
      height: 100%;
      padding-bottom: 130%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      transition: $veryslow;
    }
  }
 
  &-text {
    padding: .5rem 0;
    line-height: 1.35;
    @include tablet {
     line-height: 1.2;
    }
    &-headline {
      color: $blue;
      font-weight: 400;
      @extend %headline3;
      line-height: 1;
    }
    &-job {
      color: $black;
      @extend %headline7;
      font-family: $body;
      line-height: 1;
    }
    &-overview {
      color: $darkgray;
      font-size: 1.0rem;
      padding: 0.5rem 0;
      display: block;
      line-height: 1.15;
    }
  }
  
  &.solo {
    & .team-member-aspect {
      display: block;
      @include tablet {
        display: grid !important;
        align-items: center;
        justify-content: center;
        grid-template-columns: 350px 2fr;
        gap: 2rem;
        width: 100%;
      }
    }
    & .team-member-photo {
      max-width: 250px;
      @include tablet {
        width: -webkit-fill-available;
        max-width: 350px;
        aspect-ratio: 1 / 1.3;
      }
    }
    & .team-member-text {
      margin: 0;
      @include tablet {
        width: -webkit-fill-available;
        text-align: left;
        margin: 0 0 0 2rem;
      }
    }
  }
}
