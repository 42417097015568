.main-logo {
  width: 180px;
  margin: 0 auto;	
  display: inline-block;
  position: relative;
  z-index: 200;
  transition: $slow;
  top: 5px;
  padding: .5rem;
  &:focus {
    box-shadow: inset 0 0 0 2px $primary;
  }
  @include tablet {
    width: 300px;
  }
  @include tablet-wide {
    width: 400px;
  }
  .svg {
    width: 100%;
    fill: $primary;
    transition: $basic;
  }
  &:hover {
    .svg {
      fill: $secondary;
    }
  }
  & img {
    width: 100%;
  }
  & .logo {
    &-lg {
      display: block;
    }
    &-sm {
      display: none;
    }
  }
}
.home .main-logo {
  top: 2.5px;
  transition: $slow;
  @include tablet {
    top: 3rem;
  }
  @include tablet-wide {
    top: 3.5rem;
  }
}
.interior .main-logo {
  width: 180px;
  position: relative;
  top: 0;
  margin: 0 auto;
  @include tablet {
    width: 220px;
    position: absolute;
    top: 4rem;
    left: 1rem;
  }
  @include desktop {
    width: 263px;
    left: 3rem;
  }
 	@include md {
    left: 5rem;
 	}
}
.fixed .main-logo {
  width: 140px;
  padding: .75rem 0;
  top: initial;
  @include tablet {
    width: 180px;
    position: absolute;
    padding: 0;
    top: 4.35rem;   
    left: 1rem;
  }
  @include desktop {
    left: 3rem;
  }
 	@include md {
    left: 5rem;
 	}
  & .logo {
    &-lg {
      display: none;
    }
    &-sm {
      display: block;
    }
  }
}
