.button {
 	background: $blue;
  color: $white;
 	padding: .4rem 1rem;
 	font-family: $button;
  font-size: 1.0rem;
  line-height: .9;
  letter-spacing: 0;
  font-weight: 900;
  text-transform: uppercase;
 	display: inline-block !important;
 	text-decoration: none !important;
 	position: relative;
 	overflow: hidden;
 	transition: $bounce;
 	margin: 0;
 	outline: 0;
	 &.small {
	 }	
	 @include phone {
    font-size: 1.35rem;
    line-height: 1;
    letter-spacing: .5px;	  
	 }
	 @include tablet {
		  margin: 0;
		  font-size: 1.5rem;
		  line-height: 1.4;
		  &.small {
		  }
	 }		
	 @include tablet-wide {
	   font-size: 1.15rem;
    line-height: 1.25;
    padding: 0.5rem 1rem;
	 }
	 @include desktop {
	   font-size: 1.2rem;
    line-height: 1.3;
    padding: 0.75rem 1.5rem;		  
    margin: 0;	
		  &.small {
		  }		
	 }		
 	&:before,
 	&:after {
  		@include size(100%,50%);
  		display: block;
  		content: "";
  		z-index: 10;
  		transition: $bounce;
 	}
 	&:before {
 		 @include absolute(top 0 left 0);
 	}
 	&:after {
 		 @include absolute(bottom 0 left 0);
 	}
	 &:hover {
    color: $white;
  		transform: scale(1.1);
  		text-decoration: none !important;
	 }
 	&:active {
 		 transform: scale(.95);	
 	}
 	&:focus {
	 }
	 &.more {
     background: $clear;
     color: $black;
     border-radius: 0;
	    padding: .25rem .25rem;
	    text-decoration: none;
     &::after {
       content: "";
       width: calc(100% + .5rem);
       height: 4px;
       position: absolute;
       bottom: 0;
       left: -.25rem;
       z-index: -1;
       background: $black; 
     }
   }
   &.archive {
    margin-top: 5rem;
   }
   &.solid.light-gray {
      color: $darkgray;
   }
   &.solid.blue {
      color: $white;
   }
   &.solid.orange {
      color: $white;
   }
   &.solid.white {
      color: $black;
   }
   &.outline {
      background-color: transparent !important;
      -webkit-box-shadow:inset 0 0 0 2px $blue;
      box-shadow:inset 0 0 0 2px $blue;
      color: $blue;
      &.black {
         -webkit-box-shadow:inset 0 0 0 2px $black;
         box-shadow:inset 0 0 0 2px  $black;
         color: $black;
      }
      &.dark-gray {
         -webkit-box-shadow:inset 0 0 0 2px $darkgray;
         box-shadow:inset 0 0 0 2px $darkgray;
         color: $darkgray;
      }
      &.blue {
         -webkit-box-shadow:inset 0 0 0 2px $blue;
         box-shadow:inset 0 0 0 2px $blue;
         color: $blue;
      }
      &.white {
        	-webkit-box-shadow:inset 0 0 0 2px $white;
         box-shadow:inset 0 0 0 2px $white;
         color: $white;
      }
   }
   &.centered {
      margin: 0 auto;
   }
}

.centering {
   width: 100%;
   text-align: center;
   @include tablet {
      display: flex;
      grid-gap: 1rem;
   }
}
