.locations {
  display: none;
  background: $darkblue;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  border-top: .5rem $blue solid;
  height: 3.75rem;
  overflow: hidden;
  color: $white;
  @include tablet {
    display: block;
  }
  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    &-item {
      display: inline;
      margin-right: 1rem;
      @include desktop {
        margin-right: 2rem;
      }
      &:last-child {
        margin-right: 0;
      }
      & a {
        color: $white;
        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.main-header {
  background: none;
  width: 100%;
  position: fixed;
  height: 8.25rem;
  left: 0;
  top: 0;
  z-index: 1000;
  transition: $slow;
  @include tablet {
    background: none;
  }
  @include desktop {
    height: 8.25rem;
  }
}
.home .main-header .primary-nav  {
  position: relative !important;
  right: 1rem !important;
}
.interior .main-header {
  height: 4rem;
  transition: $slow;
  @include tablet {
    height: 8.25rem;
  }
}
.fixed .main-header {
  background: $blue;
  height: 4rem;
  transition: $slow;
  -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
  box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
  @include tablet {
    height: 8.25rem;
  }
  & .primary-nav {
    position: absolute !important;
    @include desktop {
      right: 3rem !important;
    }
    @include md {
      right: 5rem !important;
    }
  }
}

.primary-nav {
  z-index: 1000;
  width: auto;
  opacity: 1.0;
  position: absolute;
  right: 3rem;
 	display: none;
 	flex-direction: row;
 	flex-wrap: nowrap;
 	justify-content: center;
 	align-content: stretch;
 	align-items: flex-end;		
 	transition: $slow;
  @include tablet {
    display: flex;
    margin-top: 1.25rem;
    left: initial;
    right: 1rem;
  }
  @include desktop {
    margin-top: 0.85rem;
    right: 3rem;
  }
 	@include md {
    right: 5rem;
 	}
 	&-grouping {
  		&.left, &.right {
   			display: none;
   			width: 100%;
   			@include desktop {
    				display: block;
    				flex: 1 1 auto;
    				align-self: auto;
   			}
  		}			
  		&.left {
  			 text-align: right;
  		}
  		&.right {
  			 text-align: left;
  		}	
  		&.logo-container {
   			flex: 0 1 8rem;
   			transition: $slow;
   			@include tablet {
   				 flex: 0 1 11rem;
   			}			
   			@include desktop {
    				flex: 0 1 20rem;
    				align-self: baseline;
   			}
   			@include md {
    				flex: 0 1 27rem;
    				align-self: baseline;
   			}			
  		}
	 }
	 &-item {
  		font-size: 1rem;
  		display: inline-block;
  		color: $darkgray;
  		text-decoration: none;
  		cursor: pointer;	
  		text-transform: none;
  		position: relative;
  		@include tablet-wide {
  		}			
  		@include desktop {
  			 font-size: 1.25rem;
  		}	
  		@include md {
  			 font-size: 1.45rem;
  		}	
  		&:hover {
   			.primary-nav {
   				 transition: $slow;
   			  &-link { 
          color: $white;
          -webkit-box-shadow:inset 0 -.25rem 0 0 $white;
          box-shadow:inset 0 -.25rem 0 0 $white;
   			  }			  
   			}				
    		.primary-subnav {
   				 opacity: 1;
   				 visibility: visible;
   				 transition: none;
   			}
    }
  		&:focus {
   			.primary-nav {
   			  &-link { 
    				 color: $blue;
   			  }			  
   			}				
   			.primary-subnav {
    				opacity: 1;
    				visibility: visible;
    				transition: none;
   			}
  		}
  		&.active {
    		&:before {
        width: calc(100% - 1rem);
        height: 0.25rem;
     			@include absolute(left 0 bottom 0%);			
     			z-index: 200;
     			background: $white;
     			content: " ";
    		}
  		}
	 }	
 	&-list {
  		display: flex;
  		margin: 0 auto;
  		padding: 0;
  		font-family: $nav;
    font-weight: 600;
  		list-style: none;
  		position: relative;
 	}
 	&-link {
  		color: $white;
    font-weight: 600;
  		transition: $basic;	
  		padding: 0.5rem 0.25rem;
  		margin-right: 1rem;
  		display: inline-block;
    line-height: 1;
  		@include desktop {
      padding: 0.75rem 0.75rem .5rem;
  		}
  		&:focus {
    		color: $blue;
  		}
 	}
}

.primary-extras {
  width: auto;
  position: relative; 
  top: 0;
  z-index: 1001;
  padding: 0;
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-end;		
  transition: $slow;
  @include tablet {
    display: flex;
  }		
	 @include desktop {
	 }	
  @include lg {
	 }	
  &-share {
    top: initial;
    position: relative;
    display: none;
    @include tablet {
      font-size: 1.0rem;
      margin-left: .5rem;
      display: inline-block;
  	 }	
  	 @include desktop {
      font-size: 1.1rem;
      margin-left: 1.25rem;
  	 }
    & .social {
      &-link {
        width: 1.75rem;
        height: 1.75rem;
        padding: 0.3rem;
        margin: 0 0.1rem;
       	display: inline-block;	
       	transition: $slow; 
       	border-radius: 50%;
       	@include desktop {
          width: 2.25rem;
          height: 2.25rem;
          padding: 0.4rem;
          margin: 0 0.2rem;       	 
       	}
       	& svg {
       	  fill: $white;
       	}
       	&:hover {
       	  background: $white;
       	  & svg {
       	    fill: $blue;
       	  }
       	}
      }
    }
  }
}
