@font-face {
   font-family: 'hub-survey';
   src:  url('https://s3.amazonaws.com/icomoon.io/31537/HUBSurvey/hub-survey.eot?c6ymz1');
   src:  url('https://s3.amazonaws.com/icomoon.io/31537/HUBSurvey/hub-survey.eot?c6ymz1#iefix') format('embedded-opentype'),
     url('https://s3.amazonaws.com/icomoon.io/31537/HUBSurvey/hub-survey.woff2?c6ymz1') format('woff2'),
     url('https://s3.amazonaws.com/icomoon.io/31537/HUBSurvey/hub-survey.ttf?c6ymz1') format('truetype'),
     url('https://s3.amazonaws.com/icomoon.io/31537/HUBSurvey/hub-survey.woff?c6ymz1') format('woff'),
     url('https://s3.amazonaws.com/icomoon.io/31537/HUBSurvey/hub-survey.svg?c6ymz1#hub-survey') format('svg');
   font-weight: normal;
   font-style: normal;
}


.form {
   text-align: left;
   padding: 1em;
   margin: 1rem 0;
   background: $white;
   border: 1px solid rgba(0,0,0,0.2); 
   @include tablet {
     margin: 0;
     padding: 2em;
   }
   & .group {
      padding: 1em 0; 
   }
}

.fields {
   width: 100%;
   & .field {
      padding-bottom: .5em; 
      position: relative;
      text-align: left;
      &.wide {
         width: 100%;
         float: none; 
         padding-right: 0;
         display: block;
      }  
   }
}

.inline .fields .field {
  padding-bottom: .1em; 
}

.form {
   & .row { 
      padding-right: 0;
      padding-bottom: .7em; 
      display: block;
      position: relative;
   }
   & .form-bottom {
      text-align: center;
   }
   & fieldset { 
      color: $gray;
      display: block;
      padding-bottom: 1em;
      font-family: $body;
   }
   & label { 
      display: inline-block;
      padding: 0 0 .2em;
      font-size: 70%;
      cursor: pointer;
      font-family: $body;
      text-transform: uppercase;
      font-weight: 400;
      position: inherit;
      -webkit-transition: background .3s ease-in-out;
      -moz-transition:    background .3s ease-in-out;
      -o-transition:      background .3s ease-in-out;
      -ms-transition:     background .3s ease-in-out;
      transition:         background .3s ease-in-out;  
      @include tablet {
        font-size: 60%;
      }
   }
   & input, select, textarea {
      position: relative;
      border: 1px solid rgba(0,0,0,0); 
      -moz-box-shadow:    inset 0 -.15em 0 $blue;
      -webkit-box-shadow: inset 0 -.15em 0 $blue;
      box-shadow:         inset 0 -.15em 0 $blue; 
      background: $lightgray;
      border-radius: 0;
      -webkit-appearance: none;
      width: 100%;
      padding: .5em .5em;  
      font-family: $body;
      font-weight: 400;
      font-size: 100%;
      cursor: pointer;    
      -webkit-transition: all .3s ease-in-out;
      -moz-transition:    all .3s ease-in-out;
      -o-transition:      all .3s ease-in-out;
      -ms-transition:     all .3s ease-in-out;
      transition:         all .3s ease-in-out;  
      @include tablet {
        font-size: 110%;
        padding: .75em .75em;  
      }
   }	
   & textarea { 
      height: 10em; 
   }  
   & input:hover, select:hover, textarea:hover {
      border: 1px solid rgba(0,0,0,.2); 
      background: $white;
      -moz-box-shadow:    inset 0 -.15em 0 $red;
      -webkit-box-shadow: inset 0 -.15em 0 $red;
      box-shadow:         inset 0 -.15em 0 $red;   
   }
   & input:focus, select:focus, textarea:focus {  
      border: 1px solid rgba(0,0,0,.6);     
   }
   & .submit { 
      padding: 0 0 1.5em; 
      text-align: center;
   }
   & button { 
     	background: $blue;
      font-family: $button;
      text-transform: uppercase;  
      font-weight: 900;
      border: none;
      border-radius: 8px;
      padding: .5em 1.0em;
      font-size: 1.1rem;
      color: $white;
      display: block;
      width: 100%;
      margin: .5em 0;
      position: relative;
      -webkit-transition: all .15s ease-in-out;
      -moz-transition:    all .15s ease-in-out;
      -o-transition:      all .15s ease-in-out;
      -ms-transition:     all .15s ease-in-out;
      transition:         all .15s ease-in-out; 
      cursor: pointer;
    	 @include phone {
     	  border-radius: 6px;
        font-size: 1.35rem;
        line-height: 1;
        letter-spacing: .5px;	  
    	 }
    	 @include tablet {
        margin: 1em 0;
     	  border-radius: 4px;
        font-size: 1.75rem;
    	 }
   } 
   & button:hover, .form button:active { 
      background: $red;
   }
   & .small {
      font-size: 60%;
      display: block;
      margin: 1.5rem 0 .5rem 0;
      font-weight: 400;
      font-style: italic;
   }
   
   & .radio-row {
      margin: 0 0 .5em 0; 
   }
   & .radio-row .radio-item {
      display: block; 
   }
   & .radio-row input[type=radio] {
      width: auto;
      -moz-appearance: radio;
      -webkit-appearance: radio;
      margin: 0 0 0 1em;
   }
   & .radio-row input[type=radio]:hover {
      border: 0;  
      -moz-box-shadow:    none;
      -webkit-box-shadow: none;
      box-shadow:         none;   
   }
   & .radio-row input[type=radio]:focus {  
      border: 0;     
   }
   & .radio-row label:hover {
      -moz-box-shadow:    inset 0 -.15em 0 #7191cd;
      -webkit-box-shadow: inset 0 -.15em 0 #7191cd;
      box-shadow:         inset 0 -.15em 0 #7191cd;   
   }
   & .radio-row label:focus {  
   }
   & .radio-row label {
      text-transform: none;
      font-weight: 400;
   }
   & .disclaimer {
      margin: 0 auto !important;
      font-size: 70% !important;
      font-weight: 400;
      font-style: italic;
      text-align: left !important;
   }
   & .error {
      -moz-box-shadow:    inset 0 -.25em 0 #c51244;
      -webkit-box-shadow: inset 0 -.25em 0 #c51244;
      box-shadow:         inset 0 -.25em 0 #c51244; 
      background: #fff0f4; 
      color: #c51244;
   }
}

@media only screen and (min-width: 550px) and (min-device-width: 480px) {  
   .fields {
     width: 100%;
     display: table;
     padding-bottom: .4em; 
   }
   .field,
   .form .col { 
     width: 50%; 
     display: table-cell;
     vertical-align: top;
   }
   .form .col { 
     padding: .5em;
   }  
   .fields.three .field { 
     width: 33.33%; 
   }  
   .form .field { 
     padding-left: 1em; 
   }
   .form .field:first-child { 
     padding-left: 0; 
   }
   .fields.three .field:last-child { 
     padding-left: 1em; 
   }  
   .form .field.wide:last-child { 
     padding-right: 0; 
   }    
   .form .fields.address .field:last-child { 
     padding-right: 0; 
   }
   #zip-field {
     width: 30%;
   }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
}
@media only screen and (min-width: 550px) and (min-device-width: 480px) {  
   .form input,
   .form select,
   .form textarea {
     font-size: 100%;
   }   
} 


@media only screen and (min-width: 1100px) and (min-device-width: 480px) {  
 	.form button {
 		margin: 0;
 		}
}

form {
   & strong.error { 
      background: #900;
      padding: .2em .5em .3em;   
      display: block;
      white-space: nowrap;
      position: absolute; 
      left: 0;
      top: 1.5em;
      z-index: 9999;    
      font-size: .8em;
      line-height: 1;
      font-weight: 400;
      color: #fff; 
      text-transform: uppercase; 
      letter-spacing: 0;
   } 
   & strong.error:after { 
      height: 0; 
      width: 0;    
      margin-left: -6px;  
      content: " ";    
      position: absolute; 
      left: 15px; 
      top: 100%;
      border-color: rgba(153, 0, 0, 0);    
      border: solid transparent; 
      pointer-events: none;
      border-top-color: #900; 
      border-width: 6px; 
   }
}

.success {
   text-align: center;
   padding: 1em;
   margin: 1rem 0;
   background: $white;
   border: 1px solid rgba(0,0,0,0.2); 
   font-size: 125%;
   @include tablet {
      margin: 0;
      padding: 3em;
   }
   & h3 {
      text-transform: uppercase;
      color: $red;
      line-height: 1;
   } 
}

.row {
   & strong.error {
      top: 85%;
      font-size: .7em;
   } 
   & strong.error:after {
      display: none;
   } 
}

.radio-container,
.checkbox-container {
  width: 100%;
  display: block;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-content: center;
  align-items: center;
  @include tablet {
    display: flex;
  }
}

.label--checkbox,
.label--radio {
  position: relative !important;
  width: 100% !important;
  margin: .5rem;
  font-family: $body;
  line-height: 135%;
  cursor: pointer;
  @include tablet {
    width: auto !important;
  }
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  background: none !important;
  box-shadow: none !important;
  -webkit-appearance: auto !important;
  width: auto !important;
  margin: 0 0.25rem 0 0 !important;
  top: 2px;
}

[type="checkbox"] {
  box-sizing: border-box;
  background: none !important;
  box-shadow: none !important;
  -webkit-appearance: auto !important;
  width: auto !important;
  margin: 0 0.25rem 0 0 !important;
  top: 2px;
  left: 0;
  position: absolute !important;
}

span.checkbox-pad {
  display: inline-block;
  padding-left: 1rem;
}

/* ---- Placeholder Text Equalizer ---- */

form input:-moz-placeholder                 { opacity: 1; }
form input::-moz-placeholder                { opacity: 1; }
form input:-ms-input-placeholder            { color: #555; }
form input::-webkit-input-placeholder       { color: #555; }
form input:hover:-ms-input-placeholder      { color: #ffffff; }
form input:hover::-webkit-input-placeholder { color: #ffffff; }     
form input:focus:-ms-input-placeholder      { color: #4e4e4e; }
form input:focus::-webkit-input-placeholder { color: #4e4e4e; }  

form textarea:-moz-placeholder                 { opacity: 1; }
form textarea::-moz-placeholder                { opacity: 1; }
form textarea:-ms-input-placeholder            { color: #555; }
form textarea::-webkit-input-placeholder       { color: #555; }
form textarea:hover:-ms-input-placeholder      { color: #414141; }
form textarea:hover::-webkit-input-placeholder { color: #414141; }  
form textarea:focus:-ms-input-placeholder      { color: #4e4e4e; }
form textarea:focus::-webkit-input-placeholder { color: #4e4e4e; } 
